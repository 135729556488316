.ngneat-dialog-content {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

  border-radius: 0.5rem !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  animation: none !important;
}

.ngneat-dialog-backdrop {
  animation: none !important;

  &.ngneat-dialog-backdrop-visible {
    --tw-backdrop-blur: blur(12px);

    background: rgb(243 237 246 / 0.5) !important;
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }
}

.no-background {
  .ngneat-dialog-content {
    background: transparent;
    box-shadow: none !important;
    overflow: inherit;
  }
}

.new-context-definition-dialog {
  .ngneat-dialog-content {
    overflow: inherit;
  }
}
