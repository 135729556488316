#documentGoogleChart text,
#pageGoogleChart text,
#tokenGoogleChart text,
#userGoogleChart text {
  font-size: 14pt;
  font-weight: bold;
}

#documentGoogleChart text:nth-child(1),
#pageGoogleChart text:nth-child(1),
#tokenGoogleChart text:nth-child(1),
#userGoogleChart text:nth-child(1) {
  font-size: 18px;
  font-weight: normal;
}

#documentGoogleChart circle:nth-child(1) {
  fill: #0e9f6e;
  stroke-width: 0;
}

#pageGoogleChart circle:nth-child(1) {
  fill: #0ea5e9;
  stroke-width: 0;
}

#tokenGoogleChart circle:nth-child(1) {
  fill: #8b5cf6;
  stroke-width: 0;
}

#userGoogleChart circle:nth-child(1) {
  fill: #0694a2;
  stroke-width: 0;
}

#documentGoogleChart circle:nth-child(2),
#pageGoogleChart circle:nth-child(2),
#tokenGoogleChart circle:nth-child(2),
#userGoogleChart circle:nth-child(2) {
  stroke: #ffffff;
  stroke-width: 5;
}

#documentGoogleChart circle:nth-child(3) {
  fill: #0e9f6e;
  stroke: white;
  stroke-width: 3;
}

#pageGoogleChart circle:nth-child(3) {
  fill: #0ea5e9;
  stroke: white;
  stroke-width: 3;
}

#tokenGoogleChart circle:nth-child(3) {
  fill: #8b5cf6;
  stroke: white;
  stroke-width: 3;
}

#userGoogleChart circle:nth-child(3) {
  fill: #0694a2;
  stroke: white;
  stroke-width: 3;
}
