@use 'ngx-toastr/toastr';

.toast-bottom-right {
  bottom: 24px;
  right: 24px;
}

.ngx-toastr {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  width: fit-content !important;
  max-width: 384px !important;

  &:hover {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
}

.toast {
  &-info {
    @apply bg-dark-purple-100  dark:bg-dark-purple-700;
  }

  &-success {
    @apply bg-green-100  dark:bg-green-700;
  }

  &-warning {
    @apply bg-amber-100  dark:bg-amber-800;
  }

  &-error {
    @apply bg-red-100  dark:bg-red-800;
  }
}
