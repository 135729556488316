.markdown-text {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;

  pre,
  code {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    overflow-x: auto;
    hyphens: auto;
  }

  ul {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding-left: 25px !important;

    li {
      list-style: initial !important;
    }
  }
}
