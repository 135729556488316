/* -- LOGO -- */
.logo-xs {
  width: 4rem;
}

.logo-sm {
  width: 7rem;
}

.logo-md {
  width: 10rem;
}

.logo-lg {
  width: 13rem;
}

/* -- ICON -- */
.logo-icon-xs {
  width: 1.5rem;
}

.logo-icon-sm {
  width: 3rem;
}

.logo-icon-md {
  width: 4.5rem;
}

.logo-icon-lg {
  width: 6rem;
}
