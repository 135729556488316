@use 'scss/core/fonts';
@use 'scss/components/logo';
@use 'scss/components/dialog';
@use 'scss/components/popover';
@use 'scss/components/markdown';
@use 'scss/components/scrollbar';
@use 'scss/components/google-chart';

@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/tippy.js/themes/light.css';
@import '../node_modules/tippy.js/animations/scale.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../node_modules/flowbite/dist/flowbite.min.css';

:root {
  --dark-purple: #4b4453;
  --light-purple: #7860a6;
  --coral-600: #db6b63;
}

body {
  font-family: 'Nunito Sans', sans-serif !important;
}

main {
  .content-scroll-chat {
    position: absolute;
    top: 0;
    height: calc(100vh - 1rem);
  }
}

span:before {
  &::before {
    vertical-align: middle;
  }
}

.bg-full {
  height: calc(100vh - 1rem);
}

.bg-rounded {
  border: 0.5rem solid white;
  border-radius: 1rem;
}

.bg-content-h {
  height: calc(100% - 1rem);
}

.bg-content-ds {
  width: 100%;
  float: right;
  height: calc(100vh - 1rem);
}

@media screen and (min-width: 640px) {
  .bg-content-ds {
    width: calc(100% - 16rem);
    float: right;
    height: calc(100vh - 1rem);
  }
}

.nav-item.active a {
  font-weight: 600 !important;
  color: var(--coral-600);
}

.bg-app {
  background-position: center center;
  background-size: cover;
  height: auto;
}

[type='checkbox'],
[type='radio'] {
  color: var(--dark-purple) !important;
}

ol li::marker,
ul li::marker {
  color: var(--light-purple) !important;
  padding-right: 1rem;
}

.top-6 {
  top: 2rem;
}

.w-80px {
  width: 80px;
}

.max-w-59 {
  max-width: 59%;
}

.full-h-scrollable {
  height: calc(100vh - 150px);
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.content-h-scrollable {
  height: calc(100vh - 450px);
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.max-h-128 {
  max-height: 28rem;
}

.max-h-130 {
  max-height: 30rem;
}

.max-h-132 {
  max-height: 32rem;
}

.dropdown-max-h {
  max-height: 250px;
}

.chat-input-wrapper {
  $sidebarWidth: var(--sidebar-width);
  $chatHistoryWidth: var(--chat-history-width);

  width: calc(100vw - $sidebarWidth - $chatHistoryWidth);
  max-width: 50vw;
  margin: 0 auto;
  position: fixed;
  left: $sidebarWidth;
  right: $chatHistoryWidth;
  bottom: 2.5rem;
  transition: all 0.25s ease-in-out;
}

tr:last-of-type,
li:last-of-type {
  border-bottom: none;
}

.w-inherit {
  width: inherit;
}
