#documentGoogleChart text,
#pageGoogleChart text,
#tokenGoogleChart text,
#userGoogleChart text {
  font-size: 14pt;
  font-weight: bold;
}

#documentGoogleChart text:nth-child(1),
#pageGoogleChart text:nth-child(1),
#tokenGoogleChart text:nth-child(1),
#userGoogleChart text:nth-child(1) {
  font-size: 18px;
  font-weight: normal;
}

#documentGoogleChart circle:nth-child(1) {
  fill: #70c286;
  stroke-width: 0;
}

#pageGoogleChart circle:nth-child(1) {
  fill: #7aa9f7;
  stroke-width: 0;
}

#tokenGoogleChart circle:nth-child(1) {
  fill: #f07b71;
  stroke-width: 0;
}

#userGoogleChart circle:nth-child(1) {
  fill: #fcd050;
  stroke-width: 0;
}

#documentGoogleChart circle:nth-child(2),
#pageGoogleChart circle:nth-child(2),
#tokenGoogleChart circle:nth-child(2),
#userGoogleChart circle:nth-child(2) {
  stroke: #ffffff;
  stroke-width: 5;
}

#documentGoogleChart circle:nth-child(3) {
  fill: #34a853;
  stroke: white;
  stroke-width: 3;
}

#pageGoogleChart circle:nth-child(3) {
  fill: #4285f4;
  stroke: white;
  stroke-width: 3;
}

#tokenGoogleChart circle:nth-child(3) {
  fill: #ea4335;
  stroke: white;
  stroke-width: 3;
}

#userGoogleChart circle:nth-child(3) {
  fill: #fbbc05;
  stroke: white;
  stroke-width: 3;
}

.bg-green-google {
  background-color: #34a853;
}

.bg-yellow-google {
  background-color: #fbbc05;
}

.bg-red-google {
  background-color: #ea4335;
}

.bg-blue-google {
  background-color: #4285f4;
}
