::-webkit-scrollbar {
  width: 6px;
  background-color: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 6px;
  width: 6px;
}

* {
  /* For Firefox */
  scrollbar-color: #4a5568 #f9f9f9;
  scrollbar-width: thin;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
